* {
  box-sizing: border-box;
}

.nav {
  z-index: 1000;
  font-family: "Montserrat-ExtraLight";
  background-color: var(--b2);
}

.nav > .nav-header {
  display: inline;
}

.nav > .nav-header > .nav-title {
  display: inline-block;
  font-weight: bold;
  font-size: 22px;
  color: var(--f1);
  padding: 10px 10px 10px 10px;
}

.nav > .nav-btn {
  display: none;
}

.nav > .nav-links {
  display: inline;
  align-content: center;
  float: right;
  font-size: 18px;
}

.nav > .nav-links > a {
  display: inline-block;
  padding: 10px 10px 9px 10px;
  text-decoration: none;
  color: var(--f1);
}

.nav-item {
  display: inline-block !important;
}

.nav > .nav-links > a:hover,
.nav-item:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.nav > #nav-check {
  display: none;
}

@media (max-width: 600px) {
  .nav > .nav-btn {
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .nav > .nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 47px;
    padding: 13px;
  }
  .nav > .nav-btn > label:hover,
  .nav #nav-check:checked ~ .nav-btn > label {
    background-color: var(--b3);
    cursor: pointer;
  }
  .nav > .nav-btn > label > span {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 2px solid var(--f1);
  }
  .nav > .nav-links {
    z-index: 1000;
    position: absolute;
    display: block;
    width: 100%;
    background-color: var(--b3);
    height: 0px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 45px;
    left: 0px;
  }
  .nav > .nav-links > a {
    display: block;
    width: 100%;
  }
  .nav > #nav-check:not(:checked) ~ .nav-links {
    height: 0px;
  }
  .nav > #nav-check:checked ~ .nav-links {
    height: auto;
    overflow-y: auto;
  }
}
