.standardButton {
  font-family: "Montserrat-Medium";
  font-size: 2.2vmin;
  letter-spacing: 1px;
  color: var(--s2);
  background: var(--s4);
  border-color: var(--f1);
  border-radius: 25px;
  border-width: 0px;
  padding: 10px 15px;
  min-width: 90px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

@media (hover: hover) {
  .standardButton:hover {
    background-color: var(--s5);
  }
}
.standardButton:disabled,
.standardButton[disabled] {
  cursor: not-allowed;
  border: solid;
  background-color: var(--s6);
  color: var(f1);
}

.standardButton:active {
  background-color: var(--s6);
}

.standardButton:focus {
  outline: 0;
}
