.slidecontainer {
  width: 98%; /* Width of the outside container */
}

/* The slider itself */
.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 98%; /* Full-width */
  height: 14px; /* Specified height */
  background: var(--b4); /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
  border-radius: 14px;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  border-radius: 50%;
  width: 14px; /* Set a specific slider handle width */
  height: 14px; /* Slider handle height */
  background: var(--f1); /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 14px; /* Set a specific slider handle width */
  height: 14px; /* Slider handle height */
  border-radius: 50%;
  background: var(--f1); /* Green background */
  cursor: pointer; /* Cursor on hover */
}
