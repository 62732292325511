.standard-text {
  font-family: "Montserrat-ExtraLight";
  color: var(--f1);
}

.preview-video {
  -webkit-filter: opacity(70%); /* Safari 6.0 - 9.0 */
  filter: opacity(70%);
}
/* App Grid - Start*/
.app-container {
  width: 100%;
  height: 100vh;
  background-color: var(--s4);
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 47px calc(100% - 50px);
  grid-template-areas:
    "n n n n n n n n n n n n"
    "c c c c c c c c c c c c";
}

.nav-area {
  grid-area: n;
}

.content-area {
  grid-area: c;
}
/* App Grid - End*/

/* Enter Page Grid - Start*/
.enter-container {
  background-color: #1ed761;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: minmax(0, 41%) minmax(0, 8%) minmax(0, 51%);
  grid-template-areas:
    "p p p p p p p p p p p p"
    "b b b b b b b b b b b b"
    "i i i i i i i i i e e e";
}

@media (max-width: 600px) {
  .enter-container {
    background-color: #1ed761;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: minmax(0, 41%) minmax(0, 8%) minmax(0, 41%) minmax(0, 10%);
    grid-template-areas:
      "p p p p p p p p p p p p"
      "b b b b b b b b b b b b"
      "i i i i i i i i i i i i"
      "e e e e e e e e e e e e";
  }
}

.banner-area {
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  grid-area: b;
  background-color: var(--b1);
  font-size: 3vmin;
}

.preview-area {
  grid-area: p;
  background-color: var(--b2);
}

.info-area {
  display: flex;
  align-items: center;
  vertical-align: middle;
  grid-area: i;
  background-color: var(--b2);
}

.enter-area {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  grid-area: e;
  background-color: var(--b2);
}

.enter-button-container {
  margin-bottom: 4vh;
  margin-right: 2vh;
}
@media (max-width: 600px) {
  .enter-area {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: e;
    background-color: var(--b1);
  }

  .enter-button-container {
    margin-bottom: 0vh;
  }
}

/* Enter Page Grid - End*/

/*For spacing between top level li's*/
.info-area > ul > li {
  margin-bottom: 20px;
}

.info-list {
  font-size: 3vmin;
  margin: 5px;
}

li:not(:last-child) {
  margin-bottom: 10px;
}

li:first-child {
  margin-top: 10px;
}

/* Dashboard area grid start */

.dashboard-area {
  height: 55px;
  width: 100%;
  background-color: var(--b2);
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 55px;
  grid-template-areas: "i i i i c1 c1 c1 c1 c2 c2 c2 c2";
}

.dashboard-info-area {
  grid-area: i;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-c1-area {
  grid-area: c1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-c2-area {
  grid-area: c2;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Dashboard area grid end */

.simulator-container {
  background-color: var(--b2);
}

.dashboard-section-center {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.dashboard-section-left {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left baseline;
}

.dashboard-text {
  font-size: 2.1vmin;
}

.playlist-section {
  cursor: pointer;
}

.loading-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80%;
  flex-direction: column;
}

.loading-text {
  font-size: 3vmin;
}
